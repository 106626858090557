import { useState, useEffect } from 'react';

function TestAPI() {
    const [authorizedUser, setAuthorizedUser] = useState(false);
	const [path, setPath] = useState('');
	const [method, setMethod] = useState('GET');
	const [auth, setAuth] = useState(false);
	const [node, setNode] = useState(false);
	const [nodeToken, setNodeToken] = useState(null);
	const [json, setJson] = useState(false);
	const [body, setBody] = useState('');
	const [params, setParams] = useState([{ key: '', value: '' }]);

    useEffect(() => {
        const sessionToken = localStorage.getItem('sessionToken');
        if (sessionToken) {
            const url = new URL('/api/auth/getAccessFromToken', window.location.origin);
            url.searchParams.append('token', sessionToken);

            fetch(url, {
                method: 'GET',
                headers: { Accept: 'application/json' },
            })
                .then((response) => response.json())
                .then((data) => {
                    if (data.access_level === 1) {
                        setAuthorizedUser(true);
                    } else {
                        setAuthorizedUser(false);
                    }
                })
                .catch((error) => {
                    console.error('Error verifying session: ', error);
                });
        }
    }, []);

	const handleAddParam = () => {
		setParams([...params, { key: '', value: '' }]);
	};

	const handleParamChange = (index, field, value) => {
		const newParams = [...params];
		newParams[index][field] = value;
		setParams(newParams);
	};

	const handleRemoveParam = (index) => {
		const newParams = params.filter((_, i) => i !== index);
		setParams(newParams);
	};

	const handleSubmit = () => {
		if (!authorizedUser) {
			alert('You are not authorized to access this feature');
			return;
		}
		const url = new URL(path, window.location.origin);
		params.forEach((param) => {
			if (param.key && param.value) {
				url.searchParams.append(param.key, param.value);
			}
		});

		const options = {
			method,
			headers: {},
		};

		if (auth) {
			options.headers['Authorization'] = `Bearer ${localStorage.getItem('sessionToken')}`;
		}

		if (node) {
			options.headers['Authorization'] = `Bearer ${nodeToken}`;
		}

		if (json) {
			options.headers['Content-Type'] = 'application/json';
			options.body = body;
		}

		fetch(url, options)
			.then((response) => response.json())
			.then((data) => console.log(data))
			.catch((error) => console.error('Error:', error));
	};

	return (
		<div id='testAPI' style={{ display: authorizedUser ? 'block' : 'none' }}>
			<h1>Test API</h1>
			<input type="text" placeholder="Enter path" value={path} onChange={(e) => setPath(e.target.value)} />
			<div>
				<input type="radio" id="GET" name="method" value="GET" checked={method === 'GET'} onChange={(e) => setMethod(e.target.value)} />
				<label htmlFor="GET">GET</label>
			</div>
			<div>
				<input type="radio" id="POST" name="method" value="POST" checked={method === 'POST'} onChange={(e) => setMethod(e.target.value)} />
				<label htmlFor="POST">POST</label>
			</div>
			<div>
				<input type="radio" id="PUT" name="method" value="PUT" checked={method === 'PUT'} onChange={(e) => setMethod(e.target.value)} />
				<label htmlFor="PUT">PUT</label>
			</div>
			<div>
				<input type="checkbox" id="auth" checked={auth} onChange={(e) => setAuth(e.target.checked)} />
				<label htmlFor="auth">Authorization</label>
			</div>
			<div>
				<input type="checkbox" id="node" checked={node} onChange={(e) => setNode(e.target.checked)} />
				<label htmlFor="node">Node</label>
			</div>
			<div style={{ display: node ? 'block' : 'none' }}>
				<input type="text" id="nodeToken" placeholder="Node Token" value={nodeToken} onChange={(e) => setNodeToken(e.target.value)} />
			</div>
			<div>
				<input type="checkbox" id="json" checked={json} onChange={(e) => setJson(e.target.checked)} />
				<label htmlFor="json">application/json</label>
			</div>
			<div id="paramsSection">
				<h3>URL Parameters</h3>
				{params.map((param, index) => (
					<div key={index}>
						<input type="text" placeholder="Key" value={param.key} onChange={(e) => handleParamChange(index, 'key', e.target.value)} />
						<input
							type="text"
							placeholder="Value"
							value={param.value}
							onChange={(e) => handleParamChange(index, 'value', e.target.value)}
						/>
						<button onClick={() => handleRemoveParam(index)}>Remove</button>
					</div>
				))}
				<button onClick={handleAddParam}>Add Parameter</button>
			</div>
			<div id="bodySection" style={{ display: json ? 'block' : 'none' }}>
				<h3>Body</h3>
				<textarea id="requestBody" rows="36" cols="48" placeholder="Request Body" onChange={(e) => setBody(e.target.value)}></textarea>
			</div>
			<button onClick={handleSubmit}>Submit</button>
		</div>
	);
}

export default TestAPI;
