import React from "react";
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logo.png';
import './LandingHead.css';



function LandingHead(){
    const navigate = useNavigate();
    const navToDashboard = () => {
        navigate('/Dashboard/Landing');
    };

    const navToNodeEnroll = () => {
        navigate('/nodeEnroll');
    };

    const navToPlaylistSetup = () => {
        navigate('/setup')
    }
    const[searchInput, setSearchInput] = useState("");
    return(
        <>
            <img src={logo} alt="Company Logo" height="100px"/>
            <div class="navBar">
                <button class="navButton" onClick={navToDashboard}>Home</button>
                <button class="navButton" onClick={navToNodeEnroll}>Displays</button>
                <button class="navButton" onClick={navToPlaylistSetup}>Playlists</button>
            </div>
            <input
                type="text"
                placeholder="Search here"
                onChange={ev=>setSearchInput(ev.target.value)}
                value={searchInput} 
                class="search-input"
            />
        </>
    )
}
export default LandingHead