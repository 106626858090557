import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Login';
import RegistrationLogin from './RegistrationLogin';
import NodeEnroll from './NodeEnroll';
import DashboardLanding from './Dashboard/Landing';
import WebSocketComponent from './TestWebSocketComponent';
import FileUploadForm from './fileUpload';
import PlaylistSetup from './PlaylistSetup';
import TestAPI from './TestAPI';
import DigitalSign from './digitalSign';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<RegistrationLogin />} />
        <Route path="/nodeEnroll" element={<NodeEnroll />} />
        <Route path="/Dashboard/Landing" element={<DashboardLanding />} />
        <Route path="/websocket" element={<WebSocketComponent />} />
        <Route path="/fileUpload" element={<FileUploadForm />} />
        <Route path="/setup" element={<PlaylistSetup />} />
        <Route path="/testapi" element={<TestAPI />} />
        <Route path="/digitalsign" element={<DigitalSign />} />
      </Routes>
    </Router>
  );
}

export default App;