import { useEffect } from 'react';

const DigitalSign = () => {
    useEffect(() => {
        // Load CSS dynamically
        const link = document.createElement('link');
        link.rel = 'stylesheet';
        link.href = './display.css';
        document.head.appendChild(link);

        // Load display.js dynamically
        const script = document.createElement('script');
        script.src = './display.js';
        script.charset = 'utf-8';
        document.body.appendChild(script);

        return () => {
            document.head.removeChild(link);
            document.body.removeChild(script);
        };
    }, []);

    return (
        <div id="media-container">
            <img id="display" src="./PlaceHolder.png" alt="Content" />
        </div>
    );
};

export default DigitalSign;