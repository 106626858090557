import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Login.css';

function RegistrationLogin() {
    const [sixDigitCode, setCode] = useState("");
    const [token, setToken] = useState("");

    const navigate = useNavigate();

    const navToDisplay = () => {
        navigate('/digitalsign');
    };

    useEffect(() => {
        // Check for an existing token in localStorage
        const cachedToken = localStorage.getItem('nodeToken');
        
        if (cachedToken) {
            // If a token is already cached, use it directly
            setToken(cachedToken);
            setCode(localStorage.getItem('sixDigitCode')); // Retrieve cached code as well if stored
        } else {
            // If no token is cached, call the worker API to get new token and code
            const enrollmentURL = new URL('/api/enroll/nodeEnrollment_Node', window.location.origin);

            fetch(enrollmentURL, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .then(response => response.json())
            .then(data => {
                // Save the token and code in state
                setToken(data.token);
                setCode(data.code);

                // Cache the token and code in localStorage for future use
                localStorage.setItem('nodeToken', data.token);
                localStorage.setItem('sixDigitCode', data.code);
            })
            .catch(error => console.error("Error fetching the enrollment code:", error));
        }
    }, []); // Empty dependency array ensures this only runs on mount

    return (
        <div className='body-box'>
            <div className="login-box">
                <h2>ENROLL A SCREEN</h2>
                <div className='code'>
                    {sixDigitCode || "Getting your code..."}
                </div> 
                <div className='user-box'>
                    <p>Sign in to the portal and log in to your account. You can then type this code in to enroll this screen.</p>
                    <p>Then you can start displaying your masterpieces!</p>
                </div>
            </div>
            <button class="navButton" onClick={navToDisplay}>Ready to Display</button>
        </div>       
    );
}

export default RegistrationLogin;